.custom_switch{
    background-color: #e5edf5;
    border: 1px solid #dddddd;
    padding-top: 3px;
}
.custom_switch input:checked + span.circle{
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 26px;
    background-color: #2196F3;
    -webkit-transition: .4s;
    transition: .4s;
}
  
  input:focus + .circle {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .circle:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

/* #3182CE */