

html {
  scroll-behavior: smooth;
}

body{
    height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.navbar-logo{
    display: none;
}
.slideOut{
  bottom: -28px;
  transition: all 700ms ease-in;
  background-color: transparent;
  color: transparent;
}
.slideIn{
  bottom: 28px;
  transition: all 700ms ease-out;
}
.body-container{
    height: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 256px 1fr;
}
.sidebar{
    height: 100vh;
    min-height: 100vh;
    top: 0;
}
.overlay{
  background-color: #00000093;
}
.loader{
  animation: 4s spin linear infinite;
}

.support-container{
  background-image: url("./assets/supportbg.svg");
  background-repeat: no-repeat;
}


.onboarding-background{
  background-image: url("./assets/bg.svg");
  padding-top: 45px;
  padding-bottom: 45px;
  min-height: 100vh;
}
.onboarding-form-container{
  width: 459px;
  min-height: 403px;
  border: 1px solid #E0E0E0;
  border-top: 8px solid #FF6700;
  border-radius: 4px;
}
.input-wrapper input{
  color: #828282;
  font-size: 14px;
}
.css-yk16xz-control{
  color: red !important;
  border-radius: 0 !important;
  border: 1.5px solid #C4C4C4 !important;
  height: 48px;
  outline: none;
}
.css-1pahdxg-control{
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1.5px solid #FF6700 !important;
}
.input-wrapper .input:invalid,
.input-wrapper .input{
  border-bottom: 1.5px solid #C4C4C4;
}
.input-wrapper .input:valid,
.input-wrapper .input:focus,
.css-yk16xz-control:focus{
  border-bottom: 1.5px solid #FF6700 !important;
}
.indicator{
  background-color: #e0e0e0;
}
.indicator.checked{
  background-color: #FF6700;
}
.react-tel-input .flag-dropdown{
  background-color: transparent;
  /* border: none; */
}
.react-tel-input .form-control{
  width: 100%;
  border: none;
  height: 48px;
  border: 1.5px solid #C4C4C4;
  border-radius: 0;
}
.react-tel-input{
  margin-top: 2px;
}
.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}
.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #FF6700;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}
.checkbox-wrapper .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


@keyframes spin{
  from{
    transform: rotate(0deg)
  }
  to{
    transform: rotate(360deg)
  }
}

.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #219653;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #219653;
  }
  .text-success{
      color: #27AE60;
  }
  .bg-success{
      background-color: #27AE60;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }

  .show-snackbar{
    top: 54px;
    background-color: #000000;
    transition: all 1s ease;
  }

  .avatar{
    background-color: #FDAF8E;
  }

  .sidebar-nav-container{
      height: 62vh;
      overflow-y: scroll;
  }

  .sidebar-nav-container::-webkit-scrollbar {
    width: 10px;
  }
  
  .sidebar-nav-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .sidebar-nav-container::-webkit-scrollbar-thumb {
    background: #CCCCCC;
    border-radius: 12px;
  }
  
  /* Handle on hover */
  .sidebar-nav-container::-webkit-scrollbar-thumb:hover {
    background: #FF6700; 
  }
  .sidebar-nav-container a.active{
    color: #FF6700;
    background-color: #ffeded;
  }
  .sidebar-nav-container a.active svg,
  .sidebar-nav-container a.active svg path{
      fill: #FF6700;
      stroke: transparent
  }
  .menu-btn{
      width: 25px;
      height: 3px;
      background-color: #777777;
      display: flex;
      position: relative;
  }
  .menu-btn::before{
      content: "";
    width: 25px;
    height: 3px;
    background-color: #777777;
    display: block;
    position: absolute;
    top: -8px;
  }
  .menu-btn::after{
      content: "";
    width: 25px;
      height: 3px;
      background-color: #777777;
      display: block;
      position: absolute;
      bottom: -8px;
  }

  .kyc-navigation::after{
    content: "";
    position: absolute;
    bottom: -11px;
    border: 1px dashed #DDD7D7;
    width: 100%;
    z-index: 1;
  }
  .kyc-navigation:nth-child(1)::after{
    width: 45%;
    margin-left: 55%;
  }
  .kyc-navigation:nth-child(4)::after{
    width: 45%;
    margin-right: 55%;
  }

  .kyc-navigation.active::after{
    border-color: #FF6700;
  }

  .kyc-navigation.active::before{
    content: "";
    position: absolute;
    bottom: -20px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid #FF6700;
    z-index: 2;
    background-color: #fff;;
  }
  .kyc-navigation::before{
    content: "";
    position: absolute;
    bottom: -20px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid #DDD7D7;
    z-index: 2;
    background-color: #fff;;
  }

  .react-tel-input .form-control{
    width: 100% !important;
  }

  @media screen and (max-width: 1090px) {
    .body-container{
        display: grid;
        grid-template-columns: 1fr;
    }
    .sidebar{
        /* display: none; */
    }
    .navbar-logo{
        display: flex;
    }
}

.custom_switch{
  background-color: #e5edf5;
  border: 1px solid #dddddd;
  padding-top: 3px;
}
.custom_switch input:checked + span.circle{
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 26px;
  background-color: #2196F3;
  -webkit-transition: .4s;
  transition: .4s;
}
.react-tel-input .form-control{
  height: 45px !important;
}